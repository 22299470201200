<template>
  <BaseCard class="flex h-[343px] flex-shrink flex-col px-4">
    <template #header>
      <div class="flex space-x-2">
        <div>Contracts</div>
        <Tooltip
          text="The cumulative number of smart contracts deployed on a specific subnet or across all L1s over time."
          :id="1"
        >
          <InformationCircleIcon class="h-4 w-4 text-infoIconColor" />
        </Tooltip>
      </div>
    </template>
    <div
      class="flex-1 overflow-hidden rounded-lg pb-0 shadow"
      :class="loading ? 'flex h-full items-center justify-center' : ''"
    >
      <IconLoading class="m-auto h-8 w-8" v-if="loading" />
      <LineChart :series="series" :config="chartConfig" v-else />
    </div>
  </BaseCard>
</template>

<script setup>
import LineChart from '../../../Widgets/LineChart.vue';
import { ref, onMounted, computed, watch } from 'vue';
import { SubnetApi } from '../SubnetApi.js';
import { InformationCircleIcon } from '@heroicons/vue/24/outline';
import Tooltip from '../../../Shared/Tooltip.vue';
import { filterToDateTime, timeToUnix } from '../../../composeables/filters.js';
import moment from 'moment';
import IconLoading from '../../../icons/IconLoading.vue';
import BaseCard from '../../../Shared/BaseCard.vue';

const $subnetApi = new SubnetApi();
const loading = ref(false);
const chartData = ref([]);

const props = defineProps({
  subnet: Object,
  timeframe: String, // TODO SEND THESE TWO INTO THE CONTROLLER AS PARAMETERS
});

watch([() => props.subnet, () => props.timeframe], () => {
  getContracts();
});

// Contracts
async function getContracts() {
  loading.value = true;
  chartData.value = await $subnetApi.fetchContracts(
    props.subnet,
    props.timeframe,
  );
  loading.value = false;
}

onMounted(() => {
  getContracts();
});

const series = computed(() => {
  let data = chartData.value
    .sort((a, b) => (moment(a.date).isAfter(b.date) ? 1 : -1))
    .map((x) => {
      return [moment(x.date).unix() * 1000, x?.cumulativeContracts];
    });
  return data;
});

const chartConfig = ref({
  height: '300',
  name: 'Contracts',
  legend: {
    enabled: false,
  },
  lineColor: '#FE84AA',
});
</script>
