<template>
  <BaseCard class="flex h-[343px] flex-shrink flex-col px-4">
    <template #header>
      <div class="flex space-x-2">
        <div>TPS Avg and TPS Max</div>
        <Tooltip
          text="The daily average and maximum transactions per second (TPS) on a specific subnet or across all L1s over time."
          :id="1"
        >
          <InformationCircleIcon class="h-4 w-4 text-infoIconColor" />
        </Tooltip>
      </div>
    </template>
    <div
      class="flex-1 overflow-hidden rounded-lg pb-0 shadow"
      :class="loading ? 'flex h-full items-center justify-center' : ''"
    >
      <IconLoading class="m-auto h-8 w-8" v-if="loading" />
      <MultiLineChart
        :series="chartSeries"
        :config="chartConfig"
        :key="timeframe"
        v-else
      />
    </div>
  </BaseCard>
</template>

<script setup>
import MultiLineChart from '../../../Widgets/MultiLineChart.vue';
import { ref, onMounted, computed, watch } from 'vue';
import { SubnetApi } from '../SubnetApi.js';
import { InformationCircleIcon } from '@heroicons/vue/24/outline';
import Tooltip from '../../../Shared/Tooltip.vue';
import { filterToDateTime, timeToUnix } from '../../../composeables/filters.js';
import moment from 'moment';
import IconLoading from '../../../icons/IconLoading.vue';
import BaseCard from '../../../Shared/BaseCard.vue';

const $subnetApi = new SubnetApi();
const loading = ref(false);
const chartData = ref([]);

const props = defineProps({
  subnet: Object,
  timeframe: String, // TODO SEND THESE TWO INTO THE CONTROLLER AS PARAMETERS
});

watch([() => props.subnet, () => props.timeframe], () => {
  getTpsAndMaxTps();
});

const tooltipText = ref('');

// TpsAndMaxTps
async function getTpsAndMaxTps() {
  loading.value = true;
  chartData.value = await $subnetApi.fetchTpsAndMaxTps(
    props.subnet,
    props.timeframe,
  );
  loading.value = false;
}

onMounted(() => {
  getTpsAndMaxTps();
});

const chartSeries = computed(() => {
  let tmpData = { tps: [], max_tps: [] };
  chartData.value
    .sort((a, b) => (moment(a.date).isAfter(b.date) ? 1 : -1))
    .map((x) => {
      tmpData.tps.push([moment(x.date).unix() * 1000, parseFloat(x?.avgTps || 0)]);
      tmpData.max_tps.push([
        moment(x.date).unix() * 1000,
        parseFloat(x?.maxTps || 0),
      ]);
    });

  return [
    {
      name: 'TPS Avg',
      type: 'line',
      yAxis: 1,
      data: tmpData.tps,
      tooltip: {
        valueSuffix: '',
      },
      marker: {
        enabled: false,
      },
      color: '#A464E4',
      animation: false,
    },
    {
      name: 'TPS Max',
      type: 'line',
      data: tmpData.max_tps,
      tooltip: {
        valueSuffix: '',
      },
      marker: {
        enabled: false,
      },
      color: '#5CBC6E',
      animation: false,
    },
  ];
});

const chartConfig = computed(() => {
  return {
    height: '300',
    legend: {
      enabled: false,
    },
    yAxis: [
      {
        // TPS
        title: {
          text: '',
        },
        opposite: true,
        gridLineColor: '#262626',
      },
      {
        // MAX TPS
        title: {
          text: '',
        },
        gridLineColor: '#262626',
      },
    ],
  };
});
</script>
